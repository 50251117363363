.disabledRow {
  background-color: #f0f0f0 !important; /* Light gray background */
  color: #bbb !important; /* Light gray text */
}

.uploadContainer {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  transition: opacity 0.3s ease; /* Smooth transition for the background */
}

.uploadContainer:hover {
  opacity: 0.3; /* Reduce opacity of the background on hover */
}

.uploadIcon {
  position: absolute;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  color: black;
}

.uploadContainer:hover .uploadIcon {
  opacity: 1; /* Show the icon on hover */
  visibility: visible; /* Make the icon visible on hover */
}
